// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onboarding-pet-vet-assignment_title_f54ee50c h3 {\n    margin-bottom: 4px;\n}\n\n.onboarding-pet-vet-assignment_vetClinic_14e6a577 {\n    margin-top: 40px;\n}\n\n.onboarding-pet-vet-assignment_ctas_929972e9 {\n    margin-top: 48px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .onboarding-pet-vet-assignment_ctas_929972e9 {\n        margin-top: 40px;\n    }\n}", "",{"version":3,"sources":["webpack://src/user/view/onboarding-modal/onboarding-pet-vet-assignment/onboarding-pet-vet-assignment.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.title h3 {\n    margin-bottom: 4px;\n}\n\n.vetClinic {\n    margin-top: 40px;\n}\n\n.ctas {\n    margin-top: 48px;\n}\n\n@media tablet {\n    .ctas {\n        margin-top: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"title": "onboarding-pet-vet-assignment_title_f54ee50c",
	"vetClinic": "onboarding-pet-vet-assignment_vetClinic_14e6a577",
	"ctas": "onboarding-pet-vet-assignment_ctas_929972e9"
};
export default ___CSS_LOADER_EXPORT___;
