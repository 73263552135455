import { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, LoaderButton } from '@pumpkincare/shared/ui';
import {
  getUserRatingAddress,
  usePostUserPetVet,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';
import { useMutateCustomVet } from '@pumpkincare/vets';
import { VetClinic } from '@pumpkincare/vets/ui';

import styles from './onboarding-pet-vet-assignment.css';

function getIsNewCustomVet(vet, formVet) {
  return Object.keys(formVet).some(key => formVet[key] !== vet[key]);
}

function transformCustomClinic(clinic) {
  const transformedClinic = {
    origin: 'ONBOARDING',
    vet_name: clinic.name,
    state: clinic.stateProvince,
    addr: clinic.address,
    city: clinic.city,
    zip: clinic.zip,
    email: clinic.email,
  };
  return Object.keys(transformedClinic).reduce((acc, key) => {
    if (transformedClinic[key]) {
      acc[key] = transformedClinic[key];
    }
    return acc;
  }, {});
}

function OnboardingPetVetAssignment({
  onNextClick,
  pet,
  step,
  classes,
  isPatchingStep,
}) {
  const { data: userData } = useUssr();
  const { data: addressData } = useUserAddresses();
  const rating = getUserRatingAddress(addressData);

  const { id: userId } = userData || {};
  const { id: petId, name: petName, vet: petVet } = pet;
  const initialVet = step.vet_practice || petVet;

  const vetState = useRef({
    existing: initialVet,
    custom: {},
  });
  const [isVetValid, setIsVetValid] = useState(!!initialVet);

  const { mutateAsync: mutatePostUserPetVet, isLoading: isPatchingPetVet } =
    usePostUserPetVet();
  const { mutateAsync: mutatePostCustomVet, isLoading: isCreatingCustomVet } =
    useMutateCustomVet();
  const isLoading = isPatchingPetVet || isPatchingStep || isCreatingCustomVet;

  function handleVetClinicChange(payload) {
    /*
     ref for vet since VetClinic is handling vet data / state lifecycle
     state for validity since button / validity check lives in this component
     -- ref update would not trigger lifecycle / button state updated
     */
    vetState.current = payload;
    setIsVetValid(payload.valid);
  }

  async function handleOnSave() {
    const { existing, custom } = vetState.current;
    const isCustom = Object.keys(custom).length;

    const vetPracticeCustom = step.vet_practice_custom || {};
    let customVet = {};
    if (isCustom) {
      try {
        customVet = getIsNewCustomVet(vetPracticeCustom, custom)
          ? await mutatePostCustomVet(transformCustomClinic(custom))
          : vetPracticeCustom;
      } catch (e) {
        // error handling
      }
    }

    const vetChanged = isCustom
      ? step.vet_practice_custom?.id !== customVet.id
      : step.vet_practice?.id !== existing.id;

    if (vetChanged && userId && petId) {
      // dont worry i hate this too
      const payload = isCustom
        ? { vet_practice_custom_id: customVet.id }
        : { vet_id: existing.id };
      const secondPayload = isCustom
        ? { vet_practice_custom_id: customVet.id }
        : { vet_practice_id: existing.id };

      return mutatePostUserPetVet({ userId, petId, payload }).then(() =>
        onNextClick(secondPayload)
      );
    } else {
      return onNextClick();
    }
  }

  function handleSkip() {
    onNextClick();
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={styles.title}>
          <h3>Add a primary vet for {petName}.</h3>
          <Body2 className={classes.subtitle}>
            This will be your regular or most current vet.
          </Body2>
        </div>

        <VetClinic
          className={styles.vetClinic}
          onChange={handleVetClinicChange}
          selectedVet={vetState.current.existing}
          label='Vet clinic'
          placeholder='Search for a Vet...'
          zipcode={rating?.zipcode}
          noneOption={{ primary: 'My vet is not on this list' }}
          stickyFooter={'My vet is not on this list'}
          canSubmitCustomVet
        />

        <div className={classNames(styles.ctas, classes.ctas)}>
          <LoaderButton
            disabled={!isVetValid}
            isLoading={isLoading}
            onClick={handleOnSave}
            color='primary'
          >
            Confirm & Save
          </LoaderButton>
          <LoaderButton isLoading={isLoading} onClick={handleSkip} color='tertiary'>
            {petName} has not been to the vet
          </LoaderButton>
        </div>
      </div>
    </div>
  );
}

OnboardingPetVetAssignment.defaultProps = {
  classes: {},
  pet: {
    id: '',
    name: '',
    vet: null,
  },
};

OnboardingPetVetAssignment.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  pet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vet: PropTypes.object,
  }),
  step: PropTypes.shape({
    vet_practice: PropTypes.object,
    vet_practice_custom: PropTypes.object,
  }),
  isPatchingStep: PropTypes.bool,

  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    subtitle: PropTypes.string,
    ctas: PropTypes.string,
  }),
};

export default OnboardingPetVetAssignment;
