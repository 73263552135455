// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onboarding-reimbursement_rtp_efddd567 {\n    margin-top: 20px;\n}\n\n.onboarding-reimbursement_border_80a5fdf4 {\n    width: 100%;\n    border-bottom: 1px solid var(--pusheenGray);\n    margin-bottom: 4px;\n}", "",{"version":3,"sources":["webpack://src/user/view/onboarding-modal/onboarding-reimbursement/onboarding-reimbursement.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,2CAA2C;IAC3C,kBAAkB;AACtB","sourcesContent":[".rtp {\n    margin-top: 20px;\n}\n\n.border {\n    width: 100%;\n    border-bottom: 1px solid var(--pusheenGray);\n    margin-bottom: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rtp": "onboarding-reimbursement_rtp_efddd567",
	"border": "onboarding-reimbursement_border_80a5fdf4"
};
export default ___CSS_LOADER_EXPORT___;
