import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Body1,
  Bolt,
  ButtonStyles,
  ChevronRight,
  LegalBody,
} from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetAccountNumber,
  getReimbursementTargetRTPEnabled,
  usePayment,
} from '@pumpkincare/user';

import styles from './pumpkin-now-rtp.css';

function PumpkinNowRtp({ classes, onClick }) {
  const { data: paymentData } = usePayment();
  const accountNumber = getReimbursementTargetAccountNumber(paymentData);
  const isRtpEnabled = getReimbursementTargetRTPEnabled(paymentData);

  return (
    <div className={classNames(styles.root, classes.root)}>
      <div>
        <Bolt width={20} height={34} viewBox='0 0 20 34' className={styles.bolt} />
        <Body1 isBold>Get paid before you pay the vet!</Body1>
      </div>

      <LegalBody className={styles.subtitle}>
        {isRtpEnabled
          ? 'Your bank account is now set up to receive real-time payments at the vet.'
          : 'Set up a compatible bank account to get real time payments at the vet with PumpkinNow™. Verifying a bank account may take a few days.'}
      </LegalBody>

      {!isRtpEnabled ? (
        <>
          <button
            className={classNames(styles.cta, ButtonStyles.primary)}
            onClick={onClick}
          >
            {accountNumber ? 'Update direct deposit' : 'Add direct deposit'}
          </button>
          <a
            href='https://www.theclearinghouse.org/payment-systems/rtp/rtp-participating-financial-institutions'
            target='_blank'
            className={classNames(styles.cta, ButtonStyles.tertiary)}
            rel='noreferrer'
          >
            See compatible financial institutions{''}
            <ChevronRight width={28} height={26} viewBox='0 0 28 16' />
          </a>
        </>
      ) : null}
    </div>
  );
}

PumpkinNowRtp.defaultProps = {
  classes: {},
};

PumpkinNowRtp.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};

export default PumpkinNowRtp;
