import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AboutPumpkinNow } from '@pumpkincare/claims';
import { PUMPKIN_ASSETS_URL } from '@pumpkincare/config';
import { ButtonStyles, LegalBody, RouteLink } from '@pumpkincare/shared/ui';
import { canPetFileEstimate } from '@pumpkincare/user';

import usePetFromRoute from '../../../hooks/use-pet-from-route';
import TrackClaims from './track-claims';

import styles from './pet-claims.css';

function PetClaims() {
  const { mvx66PumpkinNow } = useFlags();
  const { pathPetId: petId, pet } = usePetFromRoute();
  const hasInsurance = !!pet?.policies.length;

  function getCTACopy() {
    if (!mvx66PumpkinNow) return 'Start a New Claim';

    return canPetFileEstimate(pet) ? 'Start a claim or estimate' : 'Start a claim';
  }

  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.claimButtons, {
          [styles.flexDesktop]: mvx66PumpkinNow,
        })}
      >
        <RouteLink
          to={`/pets/${petId}/file-claim`}
          color='primary'
          className={styles.claim}
        >
          {getCTACopy()}
        </RouteLink>

        {hasInsurance && !mvx66PumpkinNow ? (
          <RouteLink
            to={`/pets/${petId}/file-estimate`}
            className={styles.estimate}
            color='tertiary'
          >
            Get an Estimate
          </RouteLink>
        ) : null}

        {mvx66PumpkinNow && hasInsurance ? (
          <AboutPumpkinNow classes={{ root: styles.pumpkinNowCta }} />
        ) : null}
      </div>

      <TrackClaims />

      <div className={styles.pdf}>
        <LegalBody>Mailing or faxing your claim?</LegalBody>

        <a
          href={`${PUMPKIN_ASSETS_URL}/pdfs/pumpkin-claim-form.pdf`}
          target='_blank'
          rel='noreferrer'
          className={classNames(ButtonStyles.secondary, styles.download)}
        >
          Download Claim Form (PDF)
        </a>
      </div>
    </div>
  );
}

export default PetClaims;
