import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  CAT,
  DOG,
  FORMATTED_PARENTHESES_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  Modal,
  Spinner,
  Tabs,
  Typography,
} from '@pumpkincare/shared/ui';
import {
  canPetFileEstimate,
  getProductIsPending,
  useUserPetPhoto,
} from '@pumpkincare/user';

import Paths from '../../../app-shell/paths';
import usePetFromRoute from '../../hooks/use-pet-from-route';
import MemberCenterBanners from '../member-center-banners';
import PetClaimSubmission from './pet-claim-submission';
import PetClaimSuccess from './pet-claim-success';
import PetClaimSuccessLegacy from './pet-claim-success-legacy';
import PetClaims from './pet-claims';
import PetCoverage from './pet-coverage';
import PetPhotoUpload from './pet-photo-upload';
import PetRecords from './pet-records';

import styles from './pet-wrapper.css';

import catPlaceholder from '../shared/cat_placeholder_porkchopgray.png';
import dogPlaceholder from '../shared/dog_placeholder_porkchopgray.png';
import uploadIcon from './upload_icon_camera.png';

function getTabs(pet, claimsLabel) {
  const coverage = Paths.PetCoverage.replace(/:petId/, pet.id);
  const claims = Paths.PetClaims.replace(/:petId/, pet.id);
  const records = Paths.PetRecords.replace(/:petId/, pet.id);

  return [
    {
      label: 'Plan',
      to: coverage,
      value: coverage,
    },
    {
      label:
        claimsLabel || !canPetFileEstimate(pet) ? 'Claims' : 'Claims & Estimates',
      to: claims,
      value: claims,
    },
    {
      label: 'Records',
      to: records,
      value: records,
    },
  ];
}

const PLACEHOLDERS = {
  [DOG]: dogPlaceholder,
  [CAT]: catPlaceholder,
};

function PetWrapper() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { mvx66PumpkinNow } = useFlags();

  const { pet, isPetFetching, pathPetId } = usePetFromRoute();

  const { data: userPetPhotoData, isFetching } = useUserPetPhoto(pathPetId);

  const petName = pet?.name;

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isPetFetching && !petName) {
      dispatch(push(Paths.Landing));
    }
  }, [dispatch, isPetFetching, petName]);

  if (isPetFetching || !pet.id) {
    return (
      <div className={styles.padding}>
        <Spinner />
      </div>
    );
  }

  const isSawOnly = pet.wellness.length && !pet.policies.length;
  const activeProduct = isSawOnly ? pet.wellness : pet.policies;

  const { isPending, isPendingAndFirstDay } = getProductIsPending(activeProduct);

  function handlePetIconClick() {
    setIsModalOpen(true);
  }

  function handleModalCancel() {
    setIsModalOpen(false);
  }

  function handleOnSave() {
    setIsModalOpen(false);
  }

  function renderHeader() {
    return (
      <>
        {isModalOpen ? (
          <Modal onClose={handleModalCancel}>
            <PetPhotoUpload
              placeHolderImage={PLACEHOLDERS[pet?.species]}
              petName={petName}
              petId={pathPetId}
              petPhoto={userPetPhotoData?.location}
              onCancel={handleModalCancel}
              onSave={handleOnSave}
            />
          </Modal>
        ) : null}

        <div
          className={classNames(styles.header, {
            [styles.pendingHeader]: isPending,
          })}
        >
          <button
            className={classNames(ButtonStyles.unset, styles.petIconContainer)}
            onClick={handlePetIconClick}
          >
            <div className={styles.petIconWrapper}>
              {isFetching ? (
                <div className={styles.spinnerWrapper}>
                  <Spinner classes={{ root: styles.spinner }} />
                </div>
              ) : (
                <img
                  className={styles.petIcon}
                  src={userPetPhotoData?.location || PLACEHOLDERS[pet?.species]}
                  alt=''
                />
              )}
            </div>
            <img src={uploadIcon} alt='' className={styles.uploadIcon} />
          </button>

          <div className={styles.title}>
            <h5>{petName}</h5>
            <h2>{petName}</h2>
          </div>

          {!isPending ? (
            <Tabs
              tabsData={getTabs(
                pet || { id: pathPetId },
                mvx66PumpkinNow ? '' : 'Claims'
              )}
              activeValue={location.pathname}
              classes={{
                container: styles.tabContainer,
                tabCopy: classNames(
                  Typography.body2,
                  Typography.bold,
                  styles.tabCopy
                ),
                tab: styles.tab,
                activeCopy: styles.activeCopy,
              }}
            />
          ) : null}
        </div>
      </>
    );
  }

  return isPending ? (
    <div>
      <Switch>
        <Route path={Paths.PetCoverage}>
          <MemberCenterBanners />
          {renderHeader()}

          <div className={styles.warning}>
            {isPendingAndFirstDay ? (
              <Body2>
                Please hang tight while we process your account. Your enrollment
                information will typically be available the next business day. Check
                back soon!
              </Body2>
            ) : (
              <div>
                <Body2>We have found an issue with your account.</Body2>
                <Body2>
                  Please call{' '}
                  <a href={PHONE_NUMBER_LINK}>
                    {FORMATTED_PARENTHESES_PHONE_NUMBER}
                  </a>{' '}
                  to talk to our team to help.
                </Body2>
              </div>
            )}
          </div>
        </Route>
      </Switch>
    </div>
  ) : (
    <Switch>
      <Route path={Paths.PetCoverage}>
        <MemberCenterBanners />
        {renderHeader()}

        <PetCoverage />
      </Route>

      <Route path={Paths.PetClaims}>
        <MemberCenterBanners />
        {renderHeader()}

        <PetClaims />
      </Route>

      <Route path={Paths.PetRecords}>
        <MemberCenterBanners />
        {renderHeader()}

        <PetRecords />
      </Route>

      <Route path={Paths.PetClaimSubmission}>
        <PetClaimSubmission />
      </Route>

      <Route path={Paths.PetClaimSuccess}>
        {mvx66PumpkinNow ? <PetClaimSuccess /> : <PetClaimSuccessLegacy />}
      </Route>

      <Route path={Paths.PetEstimateSubmission}>
        <PetClaimSubmission isEstimate />
      </Route>

      <Route path={Paths.PetEstimateSuccess}>
        <PetClaimSuccessLegacy isEstimate />
      </Route>

      {/* this is just for tests */}
      <Route path={Paths.PetsWrapper}>
        <MemberCenterBanners />
        {renderHeader()}
      </Route>
    </Switch>
  );
}

export default PetWrapper;
