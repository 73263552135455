import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import Store from 'store';

import { ClaimForm } from '@pumpkincare/claims';
import { CAT, COMPLETED_CLAIM, DOG, ONE_DAY, sortByKey } from '@pumpkincare/shared';
import { Body2, ChevronLeft, RouteLink } from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetRTPEnabled,
  getUserRatingAddress,
  useMutatePostSignedUrl,
  useMutateUploadToS3,
  usePayment,
  usePetIncidentsQuery,
  useSubDiagnoses,
  useUserAddresses,
  useUserPetPhoto,
} from '@pumpkincare/user';

import { Paths } from '../../../../app-shell';
import usePetFromRoute from '../../../hooks/use-pet-from-route';
import useSubmitClaim from './use-submit-claim';

import styles from './pet-claim-submission.css';

import catPlaceholder from '../../shared/cat_placeholder_fafafa.png';
import dogPlaceholder from '../../shared/dog_placeholder_fafafa.png';

const PLACEHOLDERS = {
  [DOG]: dogPlaceholder,
  [CAT]: catPlaceholder,
};

function sortAndFormatConditions(conditions) {
  return sortByKey(conditions, 'name').map(condition => ({
    label: condition.name,
    value: condition,
  }));
}

export function formatClaimStorage(petId, claimId, { visit, conditions, request }) {
  const { amountClaimed } = visit;
  const { accident, illness, incidents, wellness } = conditions;

  return {
    petId,
    // pre mvx66PumpkinNow fields
    isEoL: [...accident, ...illness].some(
      condition => condition.conditionType.code === 'EOL'
    ),
    isHighValue: amountClaimed >= 1000,
    isWellnessClaim: wellness,
    isInsuranceClaim: Boolean(
      accident.length || illness.length || Object.keys(incidents).length
    ),
    // post mvx66PumpkinNow fields
    claimId,
    isEstimate: request.isEstimate,
    isEligibleForPumpkinNow: request.isEligibleForPumpkinNow,
  };
}

function PetClaimSubmission({ isEstimate }) {
  const { mutateAsync: mutatePostSignedUrl, isLoading: isSignedUrlLoading } =
    useMutatePostSignedUrl();
  const { mutateAsync: mutateUploadToS3, isLoading: isUploadingToS3 } =
    useMutateUploadToS3();
  const isProcessingFile = isSignedUrlLoading || isUploadingToS3;

  const { mvx66PumpkinNow } = useFlags();

  const { pet, pathPetId } = usePetFromRoute();
  const { data: userPetPhotoData } = useUserPetPhoto(pathPetId);
  const { data: addressData } = useUserAddresses();
  const { data: paymentData } = usePayment();
  const { zipcode } = getUserRatingAddress(addressData);
  const isRtpEnabled = getReimbursementTargetRTPEnabled(paymentData);

  const { data: conditionsData } = useSubDiagnoses({
    onFetch: sortAndFormatConditions,
  });
  const { data: incidentsData } = usePetIncidentsQuery(pathPetId);
  const { status, submitClaim } = useSubmitClaim(pathPetId);

  function handleSubmit(data) {
    data.current.petId = pathPetId;
    // will be assigned in form with mvx66
    if (typeof data.current.request?.isEstimate !== 'boolean') {
      data.current.request = { isEstimate, isForPaymentAtVet: false };
    }

    submitClaim(data.current).then(response => {
      Store.set(
        COMPLETED_CLAIM,
        formatClaimStorage(pathPetId, response.id, data.current),
        new Date().getTime() + ONE_DAY
      );
    });
  }

  function handleUploadToS3(file, rawFile, type = 'claim-attachment') {
    return mutatePostSignedUrl({ files: [rawFile], type })
      .then(signedUrls => {
        const signedUrlParams = signedUrls[file.name];
        file.key = signedUrlParams.fields.key;

        return mutateUploadToS3({ file: rawFile, signedUrls });
      })
      .then(() => {
        return file;
      })
      .catch(() => {});
  }

  // this should be inlined in the jsx when removing mvx66PumpkinNow
  function getTitle() {
    if (mvx66PumpkinNow) return `How can we help ${pet.name}?`;

    const type = isEstimate ? 'n estimate' : ' claim';
    return `Let's file a${type} for ${pet.name}.`;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <RouteLink to={`/pets/${pet.id}/claims`}>
          <Body2 isBold className={styles.back}>
            <ChevronLeft />
            {mvx66PumpkinNow ? ' Back' : ` ${pet.name}`}
          </Body2>
        </RouteLink>

        <img
          className={styles.petIcon}
          src={userPetPhotoData?.location || PLACEHOLDERS[pet?.species]}
          alt=''
        />

        <h3>{getTitle()}</h3>

        <ClaimForm
          pet={pet}
          zipcode={zipcode}
          conditions={conditionsData}
          incidents={incidentsData}
          onSubmit={handleSubmit}
          isSubmitting={status === 'loading'}
          isProcessingFile={isProcessingFile}
          onUploadFile={handleUploadToS3}
          isEstimate={isEstimate}
          isRtpEnabled={isRtpEnabled}
          rtpRedirect={Paths.Reimbursement}
        />
      </div>
    </div>
  );
}

PetClaimSubmission.defaultProps = {
  isEstimate: false,
};

PetClaimSubmission.propTypes = {
  isEstimate: PropTypes.bool,
};

export default PetClaimSubmission;
