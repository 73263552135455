import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import Store from 'store';

import { ClaimFormSuccess } from '@pumpkincare/claims';
import { COMPLETED_CLAIM } from '@pumpkincare/shared';
import { LegalBody, RouteLink, Spinner } from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetAccountNumber,
  getUserMailingAddress,
  usePayment,
  useUserAddresses,
} from '@pumpkincare/user';

import { Paths } from '../../../../app-shell';
import usePetFromRoute from '../../../hooks/use-pet-from-route';

import styles from './pet-claim-success.css';

function PetClaimSuccess() {
  const dispatch = useDispatch();
  const { claimId, petId, isEstimate, isEligibleForPumpkinNow } =
    Store.get(COMPLETED_CLAIM) || {};

  const { pathPetId } = usePetFromRoute();
  const { data: paymentData, isFetching: isPaymentLoading } = usePayment();
  const accountNumber = getReimbursementTargetAccountNumber(paymentData);
  const { data: addressData, isFetching: isAddressLoading } = useUserAddresses();
  const mailingAddress = getUserMailingAddress(addressData);

  useEffect(() => {
    if (!petId) {
      dispatch(replace(`/pets/${pathPetId}/file-claim`));
    } else if (pathPetId !== petId) {
      dispatch(replace(`/pets/${petId}/file-claim-success`));
    }
  }, [dispatch, pathPetId, petId]);

  function renderReimbursement() {
    if (isPaymentLoading || (!accountNumber && isAddressLoading)) return <Spinner />;

    if (accountNumber)
      return (
        <LegalBody>
          If eligible, funds for covered vet services will be deposited into account
          ending in *{accountNumber}
        </LegalBody>
      );

    return (
      <>
        <LegalBody>
          <span>If eligible, funds for covered vet services will be mailed to:</span>
          <span>
            {mailingAddress.street_1} {mailingAddress.street_2}
          </span>
          <span>
            {mailingAddress.city}, {mailingAddress.state_province}{' '}
            {mailingAddress.zipcode}
          </span>
        </LegalBody>

        <LegalBody>
          <span>Want to receive funds faster for future claims?</span>
          <RouteLink to={Paths.Reimbursement} color='cta' className={styles.link}>
            Set up account for real time payments
          </RouteLink>
        </LegalBody>
      </>
    );
  }

  return (
    <ClaimFormSuccess
      classes={{ root: styles.root }}
      claimId={claimId}
      isEstimate={isEstimate}
      isEligibleForPumpkinNow={isEligibleForPumpkinNow}
      renderReimbursement={renderReimbursement}
      redirect={`/pets/${petId}/file-claim`}
    />
  );
}

export default PetClaimSuccess;
