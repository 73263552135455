import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useFileClaimV2 } from '@pumpkincare/claims';
import { GENERIC_ERROR, useBanners } from '@pumpkincare/shared';

function useSubmitClaim(petId) {
  const dispatch = useDispatch();
  const { mvx66PumpkinNow } = useFlags();

  const { addBanner, removeAllBanners } = useBanners();

  const { mutateAsync: fileClaim, status } = useFileClaimV2(petId);

  function submitClaim(claimData) {
    removeAllBanners();

    return fileClaim(claimData)
      .then(response => {
        dispatch(
          push(
            response.is_estimate && !mvx66PumpkinNow
              ? `/pets/${response.pet_id}/file-estimate-success`
              : `/pets/${response.pet_id}/file-claim-success`
          )
        );
        window.scrollTo(0, 0);
        return response;
      })
      .catch(error => {
        addBanner(GENERIC_ERROR);

        throw error;
      });
  }

  return { status, submitClaim };
}

export default useSubmitClaim;
