import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { CAT, DOG, useBooleanInput } from '@pumpkincare/shared';
import { ButtonStyles, LegalBody, Modal } from '@pumpkincare/shared/ui';
import {
  ONBOARDING_QUERY,
  sortStepsForActivePets,
  STEP_TYPE_FUNDING_SOURCE,
  STEP_TYPE_PET_PHOTO,
  STEP_TYPE_VET_COLLECTION,
  useMutateOnboardingStep,
  useOnboardingModalSeen,
  useUserAllPetPhotos,
} from '@pumpkincare/user';

import PetPhotoUpload from '../pet-wrapper/pet-photo-upload';
import OnboardingComplete from './onboarding-complete';
import OnboardingPetVetAssignment from './onboarding-pet-vet-assignment';
import OnboardingReimbursement from './onboarding-reimbursement';
import OnboardingWelcome from './onboarding-welcome';

import styles from './onboarding-modal.css';

import catPlaceholder from '../shared/cat_placeholder_porkchopgray.png';
import dogPlaceholder from '../shared/dog_placeholder_porkchopgray.png';

const PLACEHOLDERS = {
  [DOG]: dogPlaceholder,
  [CAT]: catPlaceholder,
};

function formatViews(steps, pets) {
  const views = [{ name: 'welcome', isWelcome: true }];

  const sortedSteps = sortStepsForActivePets(steps, pets);
  sortedSteps.forEach(step => {
    views.push({
      ...step,
      name: step.type,
      isVet: step.type === STEP_TYPE_VET_COLLECTION,
      isReimbursement: step.type === STEP_TYPE_FUNDING_SOURCE,
      isPhoto: step.type === STEP_TYPE_PET_PHOTO,
      isRequest: true,
    });
  });

  views.push({ name: 'complete', isComplete: true });

  return views;
}

function OnboardingModal({ steps, pets }) {
  const queryClient = useQueryClient();
  const { isModalSeen, setIsModalSeen } = useOnboardingModalSeen();

  const [isModalOpen, toggleModalOpen] = useBooleanInput(!isModalSeen);
  const [viewIndex, setViewIndex] = useState(0);
  const { data: userPetPhotos } = useUserAllPetPhotos();

  const views = formatViews(steps, pets);

  const { mutateAsync: mutateOnboardingStep, isLoading: isPatchingOnboardingStep } =
    useMutateOnboardingStep();

  useEffect(() => {
    setIsModalSeen(true);
  }, [setIsModalSeen]);

  function handleCloseModal() {
    toggleModalOpen();
    queryClient.invalidateQueries([ONBOARDING_QUERY]);
  }

  async function handleSubmit(data = {}) {
    if (viewIndex === views.length - 1) {
      setViewIndex(0);
      handleCloseModal();
      return;
    }

    const stepId = views[viewIndex].id;
    if (stepId) {
      try {
        await mutateOnboardingStep({ stepId, ...data });
      } catch (e) {
        // error handling
      }
    }

    setViewIndex(viewIndex + 1);
  }

  return (
    <>
      <div className={styles.root}>
        <LegalBody isBold>
          Set up your pet’s profile to ensure expedited care when they need it most.
        </LegalBody>
        <button className={ButtonStyles.secondary} onClick={toggleModalOpen}>
          Complete onboarding
        </button>
      </div>

      {isModalOpen ? (
        <Modal
          onClose={handleCloseModal}
          classes={{
            container: styles.modalContainer,
          }}
          aria-label='Onboarding Modal'
          aria-description={`Step ${viewIndex + 1} of ${views.length}`}
        >
          {views.map((view, idx) => {
            const isView = idx === viewIndex;
            const pet =
              view.isVet || view.isPhoto
                ? pets.find(pet => pet.id === view.pet?.id)
                : {};
            const petPhoto = view.isPhoto
              ? userPetPhotos?.find(item => item.pet_id === pet?.id)
              : '';

            return (
              <div
                key={`${view.name}-${view.id || ''}`}
                className={classNames({
                  [styles.hidden]: !isView,
                })}
              >
                {view.isWelcome ? (
                  <OnboardingWelcome
                    onNextClick={handleSubmit}
                    classes={{ root: styles.viewIntro }}
                  />
                ) : null}

                {view.isVet ? (
                  <OnboardingPetVetAssignment
                    isPatchingStep={isPatchingOnboardingStep}
                    onNextClick={handleSubmit}
                    pet={pet}
                    step={view}
                    classes={{
                      root: styles.view,
                      content: styles.content,
                      subtitle: styles.subtitle,
                      ctas: styles.ctas,
                    }}
                  />
                ) : null}

                {view.isReimbursement ? (
                  <OnboardingReimbursement
                    isPatchingStep={isPatchingOnboardingStep}
                    onNextClick={handleSubmit}
                    classes={{
                      root: styles.view,
                      content: styles.content,
                      subtitle: styles.subtitle,
                      ctas: styles.ctas,
                    }}
                  />
                ) : null}

                {view.isPhoto ? (
                  <PetPhotoUpload
                    isExternalLoading={isPatchingOnboardingStep}
                    petName={pet.name}
                    petId={pet.id}
                    placeHolderImage={PLACEHOLDERS[pet.species]}
                    petPhoto={petPhoto?.location}
                    onSave={handleSubmit}
                    isOnboarding
                    classes={{
                      root: styles.view,
                      content: styles.content,
                      subtitle: styles.subtitle,
                      ctas: styles.ctas,
                    }}
                  />
                ) : null}

                {view.isComplete ? (
                  <OnboardingComplete
                    onNextClick={handleSubmit}
                    classes={{
                      root: styles.view,
                      content: styles.content,
                      subtitle: styles.subtitle,
                      ctas: styles.ctas,
                    }}
                  />
                ) : null}
              </div>
            );
          })}
        </Modal>
      ) : null}
    </>
  );
}

OnboardingModal.propTypes = {
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      species: PropTypes.string,
    })
  ).isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      pet: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default OnboardingModal;
