// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-claim-success_root_2f54fedd {\n    width: 100%;\n    margin: 40px auto;\n}\n\n.pet-claim-success_link_2291f9ed {\n    font-size: inherit;\n    width: 100%;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .pet-claim-success_root_2f54fedd {\n        width: var(--w8);\n        margin: 80px auto;\n    }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n    .pet-claim-success_root_2f54fedd {\n        width: var(--w7);\n        margin-bottom: 120px;\n    }\n}", "",{"version":3,"sources":["webpack://src/user/view/pet-wrapper/pet-claim-success/pet-claim-success.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,oBAAoB;IACxB;AACJ","sourcesContent":["@value tablet, desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n    width: 100%;\n    margin: 40px auto;\n}\n\n.link {\n    font-size: inherit;\n    width: 100%;\n}\n\n@media tablet {\n    .root {\n        width: var(--w8);\n        margin: 80px auto;\n    }\n}\n\n@media desktop {\n    .root {\n        width: var(--w7);\n        margin-bottom: 120px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"root": "pet-claim-success_root_2f54fedd",
	"link": "pet-claim-success_link_2291f9ed"
};
export default ___CSS_LOADER_EXPORT___;
