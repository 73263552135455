import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useBooleanInput } from '@pumpkincare/shared';
import { Body2, ButtonStyles, LoaderButton } from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetAccountNumber,
  getReimbursementTargetNameOnAccount,
  usePayment,
} from '@pumpkincare/user';

import DirectDepositSetupForm from '../../direct-deposit-setup-form';
import PumpkinNowRtp from '../../pumpkin-now-rtp';

import styles from './onboarding-reimbursement.css';

function OnboardingReimbursement({ onNextClick, classes, isPatchingStep }) {
  const { mvx66PumpkinNow } = useFlags();
  const { data: paymentData } = usePayment();
  const accountNumber = getReimbursementTargetAccountNumber(paymentData);
  const nameOnAccount = getReimbursementTargetNameOnAccount(paymentData);

  const [isCompleteAlreadyView, toggleIsCompleteAlreadyView] = useBooleanInput(
    !!accountNumber
  );

  function handleSubmit() {
    return onNextClick();
  }

  return (
    <div className={classes.root}>
      {!isCompleteAlreadyView ? (
        <DirectDepositSetupForm
          isOnboarding
          isExternalLoading={isPatchingStep}
          onSave={handleSubmit}
          onCancel={handleSubmit}
          classes={classes}
        />
      ) : (
        <div className={classes.content}>
          <h3>Confirm bank account.</h3>
          <Body2 className={classes.subtitle}>
            Your claim reimbursements will be deposited directly into this account.
          </Body2>

          <Body2
            isBold
            style={{ margin: mvx66PumpkinNow ? '20px 0 8px' : '64px 0 8px' }}
          >
            Reimbursement Method
          </Body2>
          <Body2>
            Bank Account •••• {accountNumber}&nbsp;&nbsp;|&nbsp;&nbsp;
            {nameOnAccount}
          </Body2>

          {mvx66PumpkinNow ? (
            <PumpkinNowRtp
              onClick={toggleIsCompleteAlreadyView}
              classes={{ root: styles.rtp }}
            />
          ) : null}

          <div className={classes.ctas}>
            {mvx66PumpkinNow ? <div className={styles.border} /> : null}

            <LoaderButton
              color='primary'
              onClick={handleSubmit}
              isLoading={isPatchingStep}
            >
              Confirm & Save
            </LoaderButton>

            {!mvx66PumpkinNow ? (
              <button
                className={ButtonStyles.tertiary}
                onClick={toggleIsCompleteAlreadyView}
              >
                Update Bank Account
              </button>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

OnboardingReimbursement.defaultProps = {
  classes: {},
};

OnboardingReimbursement.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    subtitle: PropTypes.string,
    ctas: PropTypes.string,
  }),

  isPatchingStep: PropTypes.bool,
  onNextClick: PropTypes.func.isRequired,
};

export default OnboardingReimbursement;
